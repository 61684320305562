import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import { OutboundLink } from '../../components/UI/OutboundLink/OutboundLink';
import Layout from '../../layouts/layout';
import * as classes from './features.module.scss';

const AidaPayPage = props => {
    const { t } = useTranslation();

    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="aida-pay-page:pageTitle">AIDA Pay</Trans>}
            subtitle={<Trans i18nKey="aida-pay-page:pageSubtitle">Transform your workflow: unleash efficiency
                                                                  with
                                                                  AIDA's advanced document and email
                                                                  management</Trans>}
            cover="aida-pay"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="aida-pay-page:pageDescription">
                <p>Welcome to the <strong>future of payment processing</strong> with AIDA Pay, a feature where each
                   document not only speaks but facilitates seamless transactions.<br />
                   Simplify your invoicing process by enabling payments through a QR code or a straightforward email
                   link, <strong>directly aligning</strong> the transaction with its corresponding document.</p>
                <p>AIDA Pay is your doorway to an <strong>extensive array of payment methods</strong> catering to
                   modern-day financial preferences.<br />
                   Receive transactions from credit card, wire, ACH, SEPA, Klarna, or even Apple/Google Pay among many
                   others, directly in your bank account.</p>
                <p>Engage in a <strong>new era</strong> of financial operations where the tedious boundaries of
                   traditional payment requests are transcended.<br />
                   AIDA Pay is more than a feature; it's a commitment to streamlined, efficient, and secure
                   transactions, ensuring you stay ahead in the financial domain.</p>
            </Trans>
        </div>
        <div className={classes.FeaturesContainer}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/aida-pay/aida-pay-payment-methods.jpg"
                            width={901}
                            height={666}
                            alt="AIDA Pay"
                            quality="90"
                            loading="lazy"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-pay-page:pageTitle')}</div>
                            <h2><Trans>Diversified payment methods for a fully digital experience</Trans></h2>
                            <Trans i18nKey="aida-pay-page:feature1">
                                <p>Delve into AIDA Pay's world, where <strong>varied payment methods</strong> translate
                                   to ease and
                                   assurance in transactions.<br />
                                   From credit card, wire, ACH, SEPA, to Apple/Google Pay, and flexible options like
                                   delayed or split payments, AIDA Pay <strong>digitizes the transactions</strong> while
                                   ensuring you receive the full amount promptly.</p>
                                <p>These tailored payment options aren't just about meeting customer preferences; they
                                   are about <strong>transitioning to fully digital</strong> to minimize payment delays,
                                   reduce financial risks, and enhance cash
                                   flow management for your business.</p>
                                <p>Opt for AIDA Pay, where diversified payment solutions morph into real-time financial
                                   benefits, all powered by <Link to="/platform/archive-document-management">robust
                                                                                                             document
                                                                                                             management</Link>.
                                </p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-pay-page:pageTitle')}</div>
                            <h2><Trans>Works directly with your existing bank account</Trans></h2>
                            <Trans i18nKey="aida-pay-page:feature2">
                                <p>Funds are transferred directly into your <strong>existing business or personal bank
                                                                                    account</strong>, ensuring there's
                                   zero disruption to your current financial setup.<br />
                                   It's a seamless extension of your financial infrastructure, designed to blend with
                                   your habitual monetary management.</p>
                                <p>No matter the payment type chosen, including installments, the <strong>full payment
                                                                                                          amount</strong> lands
                                   in your account immediately.<br />
                                   AIDA Pay keeps the familiarity of your existing banking relationships intact while
                                   introducing a realm of payment ease.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/aida-pay/aida-pay-bank-account.jpg"
                            width={901}
                            height={666}
                            alt="AIDA Pay"
                            quality="90"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/aida-pay/aida-pay-automatic-reconciliation.jpg"
                            width={901}
                            height={666}
                            quality="90"
                            loading="lazy"
                            alt="AIDA Pay"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-pay-page:pageTitle')}</div>
                            <h2><Trans>Automate payment requests and reconciliation</Trans></h2>
                            <Trans i18nKey="aida-pay-page:feature3">
                                <p>Automation is the core of AIDA Pay: smoothly transition from document approval to
                                   dispatching payment requests to your clients via email with an attached link or QR
                                   code.<br />
                                   As your customer completes the payment, experience the magic of <strong>real-time
                                                                                                           notifications
                                                                                                           and automatic
                                                                                                           reconciliation</strong>,
                                   a rhythm of efficiency uniquely crafted by AIDA Pay.</p>
                                <p>The cloud-backed feature enriches traditional document management, transforming it
                                   into a realm of efficient, controlled, and timely payment flow.<br />
                                   With AIDA Pay, you're not just <Link to="/platform/archive-document-management">managing
                                                                                                                   documents</Link>;
                                   you're orchestrating a <strong>streamlined payment process</strong>,
                                   ensuring each transaction propels you towards financial precision and heightened
                                   customer satisfaction.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-pay-page:pageTitle')}</div>
                            <h2><Trans>Your comprehensive payments control panel</Trans></h2>
                            <Trans i18nKey="aida-pay-page:feature4">
                                <p>Immerse in AIDA Pay's intuitive dashboard, your gateway to real-time payment alerts
                                   and a panoramic view of pending/received payments.<br />
                                   This isn't just a panel; it's your <strong>financial control center</strong>,
                                   simplifying interactions at every step.</p>
                                <p>With AIDA Pay's dashboard, <strong>analytics and insights</strong> on payment trends
                                   are at your fingertips: navigate through a user-friendly interface designed for
                                   effortless interaction and dive into customizable settings
                                   that allow you to tailor the dashboard to your individual needs.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/aida-pay/aida-pay-payments-dashboard.jpg"
                            width={901}
                            height={666}
                            alt="AIDA Pay"
                            quality="90"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/aida-pay/aida-pay-global-reach-compliance.jpg"
                            width={901}
                            height={666}
                            alt="AIDA Pay"
                            quality="90"
                            loading="lazy"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-pay-page:pageTitle')}</div>
                            <h2><Trans>Effortless global reach and compliance</Trans></h2>
                            <Trans i18nKey="aida-pay-page:feature5">
                                <p>With AIDA Pay, effortlessly <strong>transcend geographical boundaries</strong> and
                                   tap into the global market with full assurance.<br />
                                   Delight in automatic currency conversion, country-specific payment methods, and
                                   customer-friendly payment schemes like deferred or installment payments at no extra
                                   cost.</p>
                                <p>It's more than just global outreach: it's about nurturing a trustful relationship
                                   with international clientele.<br />
                                   Let AIDA pave the way for your <strong>global business expansion</strong>.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('aida-pay-page:pageTitle')}</div>
                            <h2><Trans>Superior security and compliance standards</Trans></h2>
                            <Trans i18nKey="aida-pay-page:feature6">
                                <p>AIDA Pay doesn’t just offer security, it <strong>guarantees it</strong>.</p>
                                <p>With <OutboundLink
                                    href="https://www.pcisecuritystandards.org/"
                                    target="_blank" rel="noopener">PCI compliance</OutboundLink>, every transaction is
                                   enveloped in robust encryption,
                                   safeguarding your financial data meticulously.<br />
                                   The cloud-backed architecture fosters a secure realm where document management
                                   coalesces with payments, forming
                                   the <strong>zenith of business solutions</strong>.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/aida-pay/aida-pay-security-pci-compliance.jpg"
                            width={901}
                            height={666}
                            quality="90"
                            loading="lazy"
                            alt="AIDA Pay"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default AidaPayPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "aida-pay-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'aida-pay-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'AIDA Pay';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
